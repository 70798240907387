/* eslint-disable limited-exports-page-templates */

import React from "react"

import { Layout, Seo } from "../../layout"
import Logo from "../../icons/logo.svg"
import { Svg } from "../../components/icon"
import { img } from "../../components"
import {
  Cover,
  Plan,
  Dream,
  Problem,
  Gol2021,
  Trailguide,
  Traction,
  SubOptimal,
  DestinationPromise,
  DestinationMap,
  DestinationProblem,
  Qr,
  ActivityMenu,
  Feedback,
  Visualization,
  Integration,
  AllActivities,
  Market,
  Team,
  Page,
  addNavigation,
  usePaging,
} from "./index.js"

export default function IndexPage(props) {
  const sections = [
    <Cover id="Cover" />,
    <Plan id="Plan" />,
    <Dream id="Dream" />,
    <Problem id="Problem" />,
    <Gol2021 id="Gol2021" />,
    <Trailguide id="Trailguide" />,
    <Traction id="Traction" />,
    <SubOptimal id="SubOptimal" />,
    <DestinationPromise id="DestinationPromise" />,
    <DestinationMap id="DestinationMap" />,
    <DestinationProblem id="DestinationProblem" />,
    <Qr id="Qr" />,
    <ActivityMenu id="ActivityMenu" />,
    <Feedback id="Feedback" />,
    <Visualization id="Visualization" />,
    <Integration id="Integration" />,
    <AllActivities id="AllActivities" />,
    <Market id="Market" />,
    <BusinessModel id="BusinessModel" />,
    <TotalAddressableMarket id="TotalAddressableMarket" />,
    <Competition id="Competition" />,
    <MainAdvantage id="MainAdvantage" />,
    <Customers id="Customers" />,
    <QuotesHallingdalRides id="QuotesHallingdalRides" />,
    <QuotesHafjell id="QuotesHafjell" />,
    <Financing id="Financing" />,
    <WhatHaveWeToday id="WhatHaveWeToday" />,
    <GoToMarket id="GoToMarket" />,
    <Team id="Team" />,
    <CapitalNeed id="CapitalNeed" />,
  ]
  usePaging(sections, "/invest/ext")

  return (
    <Layout path={props.path}>
      <Seo title="Home" />
      {sections.map(addNavigation)}
    </Layout>
  )
}

const TGLogo = () => (
  <span className={`inline-block w-24 h-24 mr-4`}>
    <Svg icon={Logo} />
  </span>
)

const TotalAddressableMarket = (props) => (
  <Page image="invest/kroner.jpg" {...props}>
    <div className="h-full w-full">
      <div className="flex justify-center mt-12">
        <div
          className="bg-black-50 text-white p-4"
          style={{ backdropFilter: "blur(12px)" }}
        >
          <table className="text-3xl">
            <tr>
              <th className="text-left px-4 py-1">Kunder</th>
              <th className="px-4 py-1">Antall</th>
              <th className="px-4 py-1">Pris</th>
              <th className="px-4 py-1">Totalt</th>
            </tr>
            <tr>
              <td className="px-4 py-1">Destinasjoner</td>
              <td className="px-4 py-1 text-right">6</td>
              <td className="px-4 py-1 text-right">50.000</td>
              <td className="px-4 py-1 text-right">300.000</td>
            </tr>
            <tr>
              <td className="px-4 py-1">Større overnattingsaktører</td>
              <td className="px-4 py-1 text-right">15</td>
              <td className="px-4 py-1 text-right">25.000</td>
              <td className="px-4 py-1 text-right">375.000</td>
            </tr>
            <tr>
              <td className="px-4 py-1">Lokale bedrifter</td>
              <td className="px-4 py-1 text-right">50</td>
              <td className="px-4 py-1 text-right">5.000</td>
              <td className="px-4 py-1 text-right">250.000</td>
            </tr>
            <tr>
              <td className="px-4 py-1">Hallingdal som region</td>
              <td className="px-4 py-1 text-right">1</td>
              <td className="px-4 py-1 text-right">150.000</td>
              <td className="px-4 py-1 text-right">150.000</td>
            </tr>
            <tr>
              <td className="px-4 py-1" colspan="3">
                Totalt
              </td>
              <td className="px-4 py-1">Kr. 1.075.000</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Totalt addresserbart marked</div>
            <div className="text-lg">
              Hallingdal som eksempel | Dupliserbart over hele verden
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const BusinessModel = (props) => (
  <Page image="webapp/earnmoney.jpg" {...props}>
    <div className="h-full">
      <ul
        className="mt-12 px-6 py-4 text-3xl text-center text-white bg-black-25"
        style={{ backdropFilter: "blur(12px)" }}
      >
        <li className="px-6 py-1">
          6. Komplett løsning med fysiske sensorer (500.000)
        </li>
        <li className="px-6 py-1">5. Løsninger til regioner (150-250.000)</li>
        <li className="px-6 py-1">
          4. Løsninger til destinasjoner (50-100.000)
        </li>
        <li className="px-6 py-1">
          3. Løsninger til overnattingsbedrifter (25-50.000)
        </li>
        <li className="px-6 py-1">
          2. Løsninger til lokale bedrifter (10-25.000)
        </li>
        <li className="px-6 py-1">
          1. Promotering av lokale bedrifter (5.000)
        </li>
      </ul>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Forretningsmodell</div>
            <div className="text-lg">
              Promotering av lokale bedrifter | Løsninger for overnatting og
              destinasjoner | Årlig
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const Competition = (props) => (
  <Page image="nesbyen_enduro_2018/bib-63.jpg" {...props}>
    <div className="h-full w-full">
      <img
        className="w-3/4 m-12 shadow-lg border mx-auto"
        alt="Our competition, komoot, google, outdoor active, fatmap, skitude, ut, trailforks"
        src={img("invest/competition2")}
      />
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Konkurrenter</div>
            <div className="text-lg">
              Noen har destinasjonsløsninger, de fleste retter seg mot
              forbrukeren. De fleste er native Apps.
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const MainAdvantage = (props) => (
  <Page image="invest/thegerman.jpg" {...props}>
    <div className="h-full w-full">
      <div className="absolute top-y-0 right-0 p-8 flex w-full justify-start items-start">
        <img
          className="w-1/4 border-4 border-gray-500 p-2 bg-white rounded-sm shadow-lg"
          alt="The QR code is our solution"
          src={img("invest/qr4D", 800)}
        />
        <img
          className="w-1/5 ml-8"
          alt="phone with trail page"
          src={img("invest/trailguide-phone-hallingspranget.png", 800)}
        />
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Konkurransefortrinn</div>
            <div className="text-lg">
              Umiddelbar aksess | Minimal nedlastning | Ingen installasjon |
              Ingen registrering
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const Customers = (props) => (
  <Page image="invest/1.jpg" {...props}>
    <div className="h-full w-full">
      <div className="absolute top-y-0 right-0 p-8 flex w-full justify-center items-start">
        <img
          className="w-3/5 rounded shadow-lg"
          alt="Pilot customers"
          src={img("invest/pilotcustomers")}
        />
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Pilotkunder</div>
            <div className="text-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

export const QuotesHallingdalRides = (props) => (
  <Page image="invest/2.jpg" {...props}>
    <div className="h-full w-full">
      <div className="absolute top-y-0 right-0 p-8 flex w-full justify-center items-start">
        <p className="w-3/5 px-6 py-4 rounded bg-black-50 text-lg text-white leading-8 italic">
          "Vi bruker Trailguide.net for å hjelpe våre besøkende til å finne frem
          til stiene i området vårt... Vi opplever tjenesten som funksjonell,
          brukervennlig, svært innovativ og fleksibel. Det er en stor fordel å
          kunne kommunisere med folka bak teknologien for å kunne formidle våre
          behov... Alt i alt fungerer tjenesten veldig godt til formålene våre -
          det er lite å sette fingeren på!"
          <div className="text-right">- Lars Storheim, Hallingdal Rides</div>
        </p>
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Kundeverdi og betalingsvillighet</div>
            <div className="text-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const QuotesHafjell = (props) => (
  <Page image="invest/2.jpg" {...props}>
    <div className="h-full w-full">
      <div className="absolute top-y-0 right-0 p-8 flex w-full justify-center items-start">
        <p className="w-3/5 px-6 py-4 rounded bg-black-50 text-lg text-white leading-8 italic">
          "Vi tog i bruk Trailguide för att kunna samla alla våra olika
          aktiviteter på en plattform... Vi använder oss mycket av QR koder som
          vi klistrar fast på skyltar för gå och sykkelturer... Vi använder
          också QR koder på alla våra sykler som länkar till en karta med
          turförslag som passar den sykelen man har hyrt. I framtiden hoppas jag
          att vi kan utöka trailguide bruket i regionen och få vårat stignätvärk
          kopplat samman med Lillehammer och längre norr i Gudbrandsdalen. Detta
          kommer göra det enklare för syklister som planerar långturer och för
          dem som vill stanna i regionen flera dagar"
          <div className="text-right">- Daniel Eriksson, Hafjell Bikepark</div>
        </p>
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Kundeverdi og betalingsvillighet</div>
            <div className="text-lg"></div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const Financing = (props) => (
  <Page image="invest/kroner.jpg" {...props}>
    <div className="h-full w-full">
      <div className="flex justify-center mt-12">
        <div
          className="bg-black-50 text-white p-4"
          style={{ backdropFilter: "blur(12px)" }}
        >
          <table className="text-2xl">
            <tr>
              <td className="px-4">ObjectPlanet AS (2013-2020)</td>
              <td className="px-4 text-right">Kr. 2.000.000</td>
            </tr>
            <tr>
              <td className="px-4">ObjectPlanet AS - kreditt og lån</td>
              <td className="px-4 text-right">Kr. 2.300.000</td>
            </tr>
            <tr>
              <td className="px-4">Tilskudd Innovasjon Norge (2017)</td>
              <td className="px-4 text-right">Kr. 700.000</td>
            </tr>
            <tr>
              <td className="px-4">Ål kommune (2017)</td>
              <td className="px-4 text-right">Kr. 200.000</td>
            </tr>
            <tr>
              <td className="px-4">Buskerud fylkeskommune (2017)</td>
              <td className="px-4 text-right">Kr. 240.000</td>
            </tr>
            <tr>
              <td className="px-4">Peter Seidl (2017)</td>
              <td className="px-4 text-right">Kr. 200.000</td>
            </tr>
            <tr>
              <td className="px-4">Ekstern Investor (2020)</td>
              <td className="px-4 text-right">Kr. 600.000</td>
            </tr>
            <tr>
              <td className="px-4">Lån Innovasjon Norge (2020)</td>
              <td className="px-4 text-right">Kr. 2.400.000</td>
            </tr>
            <tr>
              <td className="px-4">Inntekter (2018-2021)</td>
              <td className="px-4 text-right">Kr. 800.000</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Finansiering så langt</div>
            <div className="text-lg">
              Brukt til utvikling av teknologi og oppbygging av en brukerbase
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const WhatHaveWeToday = (props) => (
  <Page image="invest/3.jpg" {...props}>
    <div className="h-full">
      <ul
        className="mt-12 px-6 py-4 text-2xl text-white bg-black-25"
        style={{ backdropFilter: "blur(12px)" }}
      >
        <li className="px-6 py-2">- En fantastisk app for stisykling</li>
        <li className="px-6 py-2">
          - En plattform for digitalisering og administrasjon av
          utendørsaktiviteter
        </li>
        <li className="px-6 py-2">
          - QR-koder med umiddelbar aksess til app og innhold
        </li>
        <li className="px-6 py-2">- Visualisering av trafikkmønster</li>
        <li className="px-6 py-2">
          - Sentral database med integrasjonsløsninger
        </li>
      </ul>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Hva har vi i dag?</div>
            <div className="text-lg">
              Hva er ferdig og hva kan selges i dag?
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const GoToMarket = (props) => (
  <Page image="invest/trailguide-screen2.jpg" {...props}>
    <div className="h-full">
      <ul
        className="mt-12 px-6 py-4 text-2xl text-white bg-black-50"
        style={{ backdropFilter: "blur(12px)" }}
      >
        <li className="px-6">1. Overnattingssteder i Hallingdal</li>
        <li className="px-6">2. Overnattingssteder i Norge</li>
        <li className="px-6">3. Etablerte stiannlegg i Norge (rundt 40)</li>
        <li className="px-6">
          4. System for bestilling av promotering av egen bedrift
        </li>
        <li className="px-6">5. Strategiske partnerskap</li>
        <li className="px-6">6. Destinasjonsselskaper i Norge</li>
        <li className="px-6">7. Overnattingssteder i Europa</li>
        <li className="px-6">8. Destinasjonsselskaper i Europa</li>
        <li className="px-6">
          9. Installasjon av fysiske sensorer for datainnsamling
        </li>
      </ul>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Strategi for markedsetablering</div>
            <div className="text-lg">
              Lokalt og nasjonalt fotfeste | Europa | Fysisk datainnsamling
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

const CapitalNeed = (props) => (
  <Page image="invest/kroner.jpg" {...props}>
    <div className="h-full w-full">
      <div className="flex flex-wrap justify-center items-center my-24 text-5xl font-bold">
        <div className="px-8 py-12 text-center rounded bg-blue-900 text-blue-100 shadow-lg border-4 border-blue-100">
          Kr 10.000.000
        </div>
      </div>
      <div className="absolute flex items-center inset-x-0 bottom-0 h-40 bg-white-90 text-blue-800">
        <div className="px-12 flex items-center h-full">
          <TGLogo />
        </div>
        <div className="w-full flex flex-wrap">
          <div className="max-w-3xl">
            <div className="text-4xl">Kapitalbehov de neste tre årene</div>
            <div className="text-lg">
              Bygge opp salgsorganisasjon | teknologiutvikling | markedsføring |
              moderselskap
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)
